import React, { useContext, useEffect } from 'react';
import ThemeContext, { THEMES } from 'common/context.theme';

import AppContext from 'common/context.app';
import ArticlesShape from 'images/shapes/articles.svg';
import { HEADLINE_TYPES } from 'components/headline';
import PageHero from 'components/page-hero';
import SEO from 'components/seo';
import Styles from './article.module.css';

const ArticleTemplate = props => {
  const { setCurrentPage } = useContext(AppContext);
  const { setTheme } = useContext(ThemeContext);

  const { pageContext } = props;
  const pageData = pageContext.data;

  useEffect(() => {
    setTheme(THEMES.dark);
    setCurrentPage('/words/');
  }, [setTheme, setCurrentPage]);

  const { title, author, image, content } = pageData;

  return (
    <div className={`nhc-container-right nhc-container-left`}>
      <SEO title={title} description={author} image={image} />
      <PageHero headline={HEADLINE_TYPES.ARTICLES} shape={ArticlesShape} />

      <div className={`${Styles.layout} border-top`}>
        <div className={Styles.main}>
          <h1 className={Styles.title}>{title}</h1>
          <p className={Styles.description}>{author}</p>
          <div
            className={Styles.content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div className={Styles.sub}>
          <img
            className={Styles.image}
            src={image}
            alt=""
            aria-hidden="true"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleTemplate;
